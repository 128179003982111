<template>
	<div class="page_content">
		<div class="page_edit_content page_detail">
			<div class="title_h1">
				{{ info.type_name }}
			</div>
			<a-descriptions :column="2">
				<a-descriptions-item :span="2" label="客户编号">
					{{ info.customer_id }}
				</a-descriptions-item>
				<a-descriptions-item :span="2" label="甲方">
					{{ info.party_a }}
				</a-descriptions-item>
				<a-descriptions-item :span="2" label="乙方">
					{{ info.party_b.name }}
					{{ info.party_b.address }}
				</a-descriptions-item>
			</a-descriptions>

			<!-- <div class="content" v-html="content"></div> -->

			<div v-for="(vo,index) in content" :key="index">
				<p v-if="vo.type == 'html'" v-html="vo.content"></p>
				
			</div>
			<a-descriptions :column="2">
				<a-descriptions-item label="押金凭条">
					
						<LookImages :is_img="true" :style_img="{ width: 'auto' }" :list="[info.cash_proof]"></LookImages>
				
				</a-descriptions-item>
				<a-descriptions-item label="">
				</a-descriptions-item>
				<a-descriptions-item label="甲方代表">
					<div style="display: flex; flex-direction: column">
						{{ info.dealer_name }}
						<LookImages :is_img="true" :style_img="{ width: 'auto' }" :list="[info.dealer_sign]"></LookImages>
					</div>
				</a-descriptions-item>
				<a-descriptions-item label="乙方代表">
					<div style="display: flex; flex-direction: column">
						{{ info.linkman_name }}
						<LookImages :is_img="true" :style_img="{ width: 'auto' }" :list="[info.linkman_sign]">
						</LookImages>
					</div>
				</a-descriptions-item>
				<a-descriptions-item label="联系电话">
					{{ info.user_time }}
				</a-descriptions-item>
				<a-descriptions-item label="联系电话">
					{{ info.linkman_phone }}
				</a-descriptions-item>
				
				
				<a-descriptions-item label="日期">
					{{ info.dealer_phone }}
				</a-descriptions-item>
				
				<a-descriptions-item label="日期">
					{{ info.linkman_time }}
				</a-descriptions-item>
				
				
				
				
				<a-descriptions-item :span="2" label="监督人">
					{{ info.custodian_name || "空" }}
				</a-descriptions-item>
				<a-descriptions-item :span="2" label="监督电话">
					{{ info.custodian_phone || "空" }}
				</a-descriptions-item>
			</a-descriptions>
		</div>
	</div>
</template>

<script>
	import LookImages from "@/components/LookImages";
	
	import Avatar from "@/components/small/Avatar";
	import {
		getContractInfo
	} from "@/api/freezer";
	export default {
		components: {
			LookImages,
			Avatar
		},
		data() {
			return {
				info: {
					party_b: {},
					goods_data: [],
					operation: [],
				},
				columns: [],
				decompose: []
			}
		},
		computed: {
			content() {
				let html = [{
					type: 'html',
					content: this.info.template_content
				}];
				let template_instruction = ['goods_title', 'goods_model', 'goods_code', 'start_time', 'end_time', 'month', 'cash', 'floor',
					'region', 'remark','vertical_num','money'
				];
				template_instruction.forEach(item => {
					html.forEach((element, index) => {
						if (element.type == 'html' && element.content) {
							if (element.type == 'html') {
								let str = this.info[item];
								if (item == 'remark') {
									str = this.info['remark'] || '无';
								} else if (item == 'date') {
									str = this.info.start_time + ' 至 ' + this.info.end_time;
								} else if (item == 'goods_title') {
									str = this.info.goods_title;
								} else if (item == 'goods_model') {
									str = this.info.goods_model;
								} else if (item == 'goods_code') {
									str = this.info.goods_code;
								} else if (item == 'floor') {
									str = this.info.floor;
								} else if (item == 'region') {
									str = this.info.region;
								} else if (item == 'vertical_num') {
									str = this.info.vertical_num;
								}
								else if (item == 'money') {
									str = this.info.money;
								}
								html[index].content = html[index].content.replace(`--${item}--`,
									`<span ">${str}</span>`
									)
							}
						}
					})

				})
				return html
			}
		},
		created() {
			this.get_info()
			this.$nextTick(() => {
				setTimeout(() => {

				}, 5000)
			})
		},
		methods: {
			get_info() {
				getContractInfo({
					data: {
						id: this.$route.query.id,
					}
				}).then(res => {
					this.info = res.data.data
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.title_h1 {
		text-align: center;
		font-size: 30px;
		font-weight: bold;
		padding: 30px 0 50px;
	}

	.page_detail {
		padding: 20px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	}

	.card {
		margin-bottom: 10px;

		.goods_box {
			.goods_group {
				margin-bottom: 12px;

				.goods_item {
					display: flex;
					justify-content: space-between;
					font-size: 14px;
					color: #333333;
					margin-bottom: 8px;
					line-height: 25px;

					.right {
						min-width: 40px;
						text-align: right;
						border-left: 1px solid #ededef;

					}

					.left {
						display: flex;

						.title {
							font-weight: bold;
							font-size: 15px;
							white-space: nowrap;
						}

						.content {
							.goods {
								padding-right: 8px;

							}

							.desc {
								color: #888888;
							}
						}
					}
				}
			}
		}
	}
</style>